import {useEffect} from 'react';
import {Button, Table} from "antd";
import User from "./User";
import {getUsers} from "../../store/asyncThunkFunctions/Api/UserRequests";
import {useDispatch} from "react-redux";
import {userColumns} from "../../utils/tableUtils/columns/user";

const Users = (
    {
        handleAddNew,
        users,
        handlePreviewUser,
        handleEditUser,
        handleRemoveUser,
        usersLoading,
        onChange,
        previewClasses,
        userClasses,
        user,
        close
    }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsers())
    }, []);

    return (
        <div className={'wrapperAdministration'}>
            <Table
                sticky
                dataSource={users}
                columns={userColumns(handlePreviewUser, handleEditUser, handleRemoveUser)}
                loading={usersLoading}
                rowKey={({_id}) => _id}
                onChange={onChange}
                title={() => (
                    <div className={'wrapperAdministration__blockAddNewButton'}>
                        <Button onClick={handleAddNew} type="primary">Add new user</Button>
                    </div>
                )}
            />
            <section className={previewClasses} onClick={close}/>
            <User {...user} className={userClasses} close={close}/>
        </div>
    );
};

export default Users;