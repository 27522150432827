import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {changeEditCameraData, switchModalShowCamera} from "../../store/slices/Api/newGate";
import {useDispatch} from "react-redux";
import {handleOnChangeIsUpdateDataStatus, removeCamera} from "../../store/slices/Api/sliceGateRequest";
import {ConfirmTitles, TooltipTitles} from "../../utils/constants/objects/configuration";
import {
    deleteCameraById, editCameraById, getCameraRequest
} from "../../store/asyncThunkFunctions/Api/CamerasRequests";
import {CLOSE, MARK, PENCIL, TRASH} from "../icons/antd";
import classNames from "classnames";
import {Button, Popconfirm, Switch, Tooltip} from "antd";
import {FORM_GATE_EDIT_CAMERA_LINK} from "../../utils/routeUtils/Links";
import PreviewCameraButton from "./PreviewCameraButton";
import CalibrationStatusTooltip from "./CalibrationStatusTooltip";
import {extraCalibrationColorStatues} from "../../utils/constants/arrays/configuration";
import {calibrationTooltipColors} from "../../utils/constants/objects/configuration";

const CameraButtons = ({camera: {cctv_id, mode, calibration: {status, error}, position, is_connected}, _id, camera}) => {
    const navigation = useNavigate()
    const dispatch = useDispatch()

    const [cameraMode, setCameraMode] = useState(false);

    const btnToggleClasses = classNames('btn-toggle', {'modified': cameraMode, 'unaltered': !cameraMode});

    const onChangeCameraMode = (camera_mode) => setCameraMode(camera_mode)

    const handleEditCamera = async () => {
        await dispatch(getCameraRequest({name: _id, id: cctv_id}))
        navigation(FORM_GATE_EDIT_CAMERA_LINK(_id, cctv_id, 'step_two'))
    }

    const handleRemoveCamera = async () => {
        const params = {_id, cctv_id}
        await dispatch(deleteCameraById(params))
        await dispatch(removeCamera(params))
        await dispatch(handleOnChangeIsUpdateDataStatus(true))
    }

    const showCameraInfo = async () => {
        await dispatch(changeEditCameraData(camera))
        await dispatch(switchModalShowCamera(true))
    }

    const handleChangeMode = async (value) => {
        const mode = value ? 'active' : 'disabled'
        const arg = {cctv_id, _id, mode} // old
        const argUpdateCamera = {old: arg['cctv_id'], new: arg['cctv_id'], _id, camera: {mode, cctv_id}} // new
        await setCameraMode(value)
        await dispatch(editCameraById(argUpdateCamera))
        await dispatch(handleOnChangeIsUpdateDataStatus(true))
    }

    useEffect(() => {
        if (mode === 'active') {
            onChangeCameraMode(true)
        } else {
            onChangeCameraMode(false)
        }
    }, [mode, camera]);

    return (
        <div className={'configuration-camera'}>
            <header className={'configuration-camera__camera-title'}>
                <div className={"camera-icon-result-container"}>
                    <PreviewCameraButton
                        handler={showCameraInfo}
                        isConnected={is_connected}
                        isDisabled={mode === "disabled"}
                    />
                    <CalibrationStatusTooltip
                        status={status}
                        position={position}
                        error={error}
                        statusColor={extraCalibrationColorStatues.includes(status) ? "#ffffff" : "#000000"}
                        tooltipColor={calibrationTooltipColors[status]}
                    />
                </div>
                <span className={'camera-number'}>{cctv_id}</span>
            </header>

            <section className={'configuration-camera__btn-camera-container'}>
                <Popconfirm title={ConfirmTitles.edit} onConfirm={handleEditCamera}>
                    <Tooltip placement="bottomRight" title={TooltipTitles.editCamera}>
                        <Button icon={PENCIL} type={'primary'} className={'btn-camera'}/>
                    </Tooltip>
                </Popconfirm>

                <Popconfirm title={ConfirmTitles.remove} onConfirm={handleRemoveCamera}>
                    <Tooltip placement="bottom" title={TooltipTitles.removeCamera}>
                        <Button icon={TRASH} type={'primary'} className={'btn-camera'}/>
                    </Tooltip>
                </Popconfirm>

                <Tooltip placement="bottomLeft" title={cameraMode ? TooltipTitles.activeCamera : TooltipTitles.disableCamera}>
                    <Switch checkedChildren={MARK} unCheckedChildren={CLOSE}
                            checked={cameraMode} onChange={handleChangeMode} className={btnToggleClasses}
                    />
                </Tooltip>
            </section>
        </div>
    );
};

export default CameraButtons;