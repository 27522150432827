import React from 'react';
import {Tooltip} from "antd";
import {STOP} from "../icons/antd";
import classNames from "classnames";
import {ReactComponent as CalibrationResultIcon} from "../../assets/svg/target-30.svg"
import CalibrationTooltipInformation from "./CalibrationTooltipInformation";

const CalibrationStatusTooltip = ({status, position, error, tooltipColor, statusColor}) => {

    const calibrationResultClasses = classNames("camera-icon-result-container__calibration-result", {
        "excellent": status === "excellent",
        "good": status === "good",
        "bad": status === "bad",
        "animated": status === "validating" || status === "calibrating",
        "failed": status === "failed",
        "bridge": position === "bridge"
    });

    const tooltipTitle = <CalibrationTooltipInformation status={status} error={error} statusColor={statusColor}/>

    return (
        <div className={calibrationResultClasses}>
            <Tooltip placement={"topLeft"} title={tooltipTitle} color={tooltipColor}>
                <CalibrationResultIcon/>
                {status === "failed" && <span className={"failed-icon-result"}>{STOP}</span>}
            </Tooltip>
        </div>
    );
};

export default CalibrationStatusTooltip;