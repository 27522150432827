import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Select, TimePicker} from 'antd'
import {useDispatch, useSelector} from "react-redux";
import {getListOfGateNames} from "../store/asyncThunkFunctions/Api/GateRequest";
import {getS3Files, getYmlFiles} from "../store/asyncThunkFunctions/Api/FlightsFilesRequests";
import {getInitialState} from "../store/slices/Api/FlightsFiles";
import {addTurnPost} from "../store/asyncThunkFunctions/Api/MockRequests";
import {resetGateState} from "../store/slices/Api/sliceGateRequest";
import S3Information from "../components/Mock/S3Information";
import {returnUtcTime} from "../utils/helpers/timeHelper";
import {createOptionsSelect} from "../utils/helpers/dataHelpers/arrayObjectModifiers";
import {selectIsAddTurnPost, selectS3Files, selectYmlFiles} from "../store/selectors/Api/flightsFile";
import {selectGateNames} from "../store/selectors/Api/gates";
import dayjs from "dayjs";

const Mock = () => {
    const dispatch = useDispatch();

    const [yml, setYml] = useState(null);
    const [time, setTime] = useState(dayjs().add(1, "minute"));
    const _idRef = useRef(null);

    const {ymlFiles, isYmlFilesLoading} = useSelector(selectYmlFiles);
    const {s3Files, isS3FilesLoading} = useSelector(selectS3Files);
    const isAddTurnPost = useSelector(selectIsAddTurnPost);
    const {gatesName, isGateNamesLoading} = useSelector(selectGateNames);

    const getGateNameOptions = useMemo(() => createOptionsSelect(gatesName), [gatesName]);
    const getYmlFileOptions = useMemo(() => createOptionsSelect(ymlFiles), [ymlFiles]);

    const modifiedYmlData = useMemo(() => {
        return {
            ...s3Files,
            Arrival: {
                ...s3Files['Arrival'],
                STA: returnUtcTime(time).format('HH:mm:ss')
            }
        }
    }, [s3Files, time])

    const onChangeDefaultTime = useCallback(() => setTime(dayjs().add(1, "minute")), [])

    const handleGetYmlFiles = (_id) => {
        dispatch(getYmlFiles(_id));
        _idRef.current = _id
        if (ymlFiles.length) setYml(null)
    }

    const handleGetS3File = (yml) => {
        setYml(yml);
        dispatch(getS3Files({yml, gateName: _idRef.current}))
        onChangeDefaultTime();
    }

    const handleGetTime = (value) => {
        if (value) setTime(value);
        else onChangeDefaultTime()
    }

    const handleAddNewTurnPost = () => dispatch(addTurnPost(modifiedYmlData));

    useEffect(() => {
        dispatch(getListOfGateNames())
        return () => {
            dispatch(getInitialState())
            dispatch(resetGateState())
        }
    }, []);

    return (
        <div className={'mock_wrapper'}>
            <section className={'mock_wrapper__mock_form_section'}>
                <Select
                    className={'mock_select'}
                    placeholder={'Select gate name'}
                    options={getGateNameOptions}
                    onChange={handleGetYmlFiles}
                    loading={isGateNamesLoading}
                />
                <Select
                    value={yml}
                    className={'mock_select'}
                    placeholder={'Select YML file'}
                    options={getYmlFileOptions}
                    loading={isYmlFilesLoading}
                    onChange={handleGetS3File}
                    disabled={!ymlFiles.length}
                />
                <TimePicker
                    value={time}
                    onChange={handleGetTime}
                    className={'time_picker_mock'}
                    disabled={!yml}
                    format="HH:mm:ss"
                />
                <Button
                    className={"submit_mock_btn"}
                    onClick={handleAddNewTurnPost}
                    loading={isAddTurnPost}
                    type="primary"
                    disabled={!Object.keys(s3Files).length}
                >Submit
                </Button>
            </section>
            <S3Information
                {...modifiedYmlData}
                isEmpty={!Object.keys(s3Files).length}
                isS3FilesLoading={isS3FilesLoading}
            />
        </div>
    );
};

export default Mock;