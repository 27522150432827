import React from "react";
import {Route, Routes} from "react-router-dom";
import RoleAccess from "../routes/security/RoleAccess";
import {systemList} from "../utils/routeUtils/routes/system";
import FallBackRoute from "../routes/security/FallBackRoute";
import {fallbackPath, homeLink} from "../utils/routeUtils/Paths";

const System = () => {
    return (
        <div className={'systemWrapper'}>
            <Routes>
                {systemList.map(({element, path, roles}) => {
                    return (
                        <Route element={<RoleAccess roles={roles}/>} key={path}>
                            <Route path={path} element={element}/>
                        </Route>
                    )
                })}
                <Route path={fallbackPath} element={<FallBackRoute homeLink={homeLink}/>}/>
            </Routes>
        </div>
    );
};

export default System;