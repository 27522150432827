import {useEffect, useState} from 'react';
import {Button, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getAllGates} from "../store/asyncThunkFunctions/Api/GateRequest";
import {returnCorrectTimeZoneTime} from "../utils/helpers/timeHelper";
import useToggle from "../hooks/useToogle";
import {resetGateState, handleOnChangeIsUpdateDataStatus} from "../store/slices/Api/sliceGateRequest";
import classNames from "classnames";
import BuildTurnaroundInfo from "../components/TurnaroundInformation/BuildTurnaroundInfo";
import ModalWindow from "../components/Customs/ModalWindow";
import {paginationSettings} from "../utils/tableUtils/tableSettings";
import usePagination from "../hooks/usePagination";
import {liveViewColumns} from "../utils/tableUtils/columns/liveView";
import {cameraColumns} from "../utils/tableUtils/columns/camera";
import {clearImgStates} from "../store/slices/Api/Frames";
import {returnResendingInitialState, setIsOpenExpandableRowItem} from "../store/slices/Ui/Resending";
import {selectTimeZone} from "../store/selectors/Ui/TimeZone";
import {selectAllGatesSlice} from "../store/selectors/Api/gates";
import {getStorageValue, setCacheTableSettingsGroup} from "../utils/helpers/cacheHelpers";
import {scrollByY} from "../utils/helpers/tableScrollHelpers";
import {LIVE_VIEW_BUSY_SPACE} from "../utils/Sizes";

const LiveView = () => {
    const {page, limit, setSettings} = usePagination();
    const dispatch = useDispatch();
    const turnToggle = useToggle();

    const timeZone = useSelector(selectTimeZone);
    const {gates, loading, total, isUpdateGateData} = useSelector(selectAllGatesSlice);

    const [turn, setTurn] = useState({});

    const turnWrapper = classNames('wrapperMainTurnInfo', turnToggle.previewCheckClasses);
    const turnContent = classNames('contentMainTurnInfo', turnToggle.previewCheckClasses);

    const updateLastCameraDate = (date) => returnCorrectTimeZoneTime(date, timeZone);

    const handleCloseTurnModal = () => {
        turnToggle.close()
        setTurn({});
    }

    const handleGetTurnDate = async (turnaround) => {
        await setTurn(turnaround)
        await turnToggle.open()
    }

    const refreshLiveView = ({page = 1, limit = 5, tools}) => {
        dispatch(handleOnChangeIsUpdateDataStatus(true))
        setSettings(page, limit, tools);
    }

    const handleChangePage = ({ current: page, pageSize: limit }) => {
        const liveViewSettings = {page, limit};
        setCacheTableSettingsGroup('liveView', liveViewSettings);
        refreshLiveView(liveViewSettings);
    }

    const handleOnChangeExpandedRowState = (_id, isOpen) => dispatch(setIsOpenExpandableRowItem({_id, isOpen}))

    useEffect(() => {
        const liveViewCacheSettings = getStorageValue('tableGroupsSetting', {}, 'local')

        if (liveViewCacheSettings.liveView) refreshLiveView(liveViewCacheSettings.liveView);
        else dispatch(handleOnChangeIsUpdateDataStatus(true))
    }, [])

    useEffect(() => {
        if (isUpdateGateData) {
            dispatch(getAllGates({page, limit}))
            dispatch(handleOnChangeIsUpdateDataStatus(false))
        }
    }, [isUpdateGateData]);

    useEffect(() => {
        return () => {
            console.log('lost liveView page')
            dispatch(resetGateState())
            dispatch(returnResendingInitialState())

            dispatch(clearImgStates())
        }
    }, [])

    return (
        <div className={'wrapperMainPage'}>
            <Table
                columns={liveViewColumns(handleGetTurnDate)}
                onChange={handleChangePage}
                dataSource={gates}
                expandable={{
                    expandedRowRender: ({cameras, turnaround, _id}) => {
                        return (
                            <Table
                                rowClassName={record => record.mode === 'disabled' && 'disabled-row'}
                                columns={cameraColumns(updateLastCameraDate, turnaround?.id, _id)}
                                dataSource={cameras} pagination={false}
                                rowKey={({cctv_id}) => cctv_id} className={'nestedTable'}
                            />
                        )
                    },
                    defaultExpandedRowKeys: ['0'],
                    onExpand: (isOpen, row) => handleOnChangeExpandedRowState(row['_id'], isOpen)
                }}
                rowKey={({_id}) => _id}
                loading={loading}
                pagination={{...paginationSettings(page, limit, total)}}
                scroll={{y: scrollByY(LIVE_VIEW_BUSY_SPACE)}}
            />
            <ModalWindow modalClasses={turnWrapper} handleClose={handleCloseTurnModal}>
                <div className={turnContent}>
                    <BuildTurnaroundInfo turnaround={turn}/>
                    <Button onClick={handleCloseTurnModal}>Close</Button>
                </div>
            </ModalWindow>
        </div>
    );
};

export default LiveView;