import React from 'react';
import {useDispatch} from "react-redux";
import {getTurnaround} from "../../store/asyncThunkFunctions/Api/TurnaroundRequests";

const FidsModalButton = ({id, handleOpenModal}) => {
    const dispatch = useDispatch()

    const handleClickGetTurn = async () => {
        await dispatch(getTurnaround(id))
        await handleOpenModal()
    }

    return (
        <div className={'wrapperFidsIdButton'}>
            <button className={'wrapperFidsIdButton__fidsIdButton'} onClick={handleClickGetTurn}>{id}</button>
        </div>

    )
};

export default FidsModalButton;