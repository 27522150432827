import {useEffect} from 'react';
import UserForm from "./UserForm";
import {useDispatch} from "react-redux";
import {createUser} from "../../store/asyncThunkFunctions/Api/UserRequests";
import {useNavigate} from "react-router-dom";
import {getStorageValue} from "../../utils/helpers/cacheHelpers";
import {defaultUserState} from "../../utils/constants/objects/formInitialStates";
import {ADMINISTRATION_LINKS} from "../../utils/routeUtils/Links";

const AddUser = () => {
    const defaultState = getStorageValue('user_fields', defaultUserState);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const {users} = ADMINISTRATION_LINKS

    const handleAddNewUser = async (values) => {
        await dispatch(createUser(values))
        await navigation(users);
    };

    useEffect(() => {
        return () => sessionStorage.removeItem('user_fields');
    }, [])

    return (
        <div className={'wrapperUserForm'}>
            <UserForm
                formName={'new_user'}
                submitText={'ADD'}
                bodyClass={'bodyNewForm'}
                onFinish={handleAddNewUser}
                defaultState={defaultState}
                isNewUser
            />
        </div>
    );
};

export default AddUser;