import LiveView from "../../../pages/LiveView";
import Live from "../../../pages/Live";
import Turnarounds from "../../../pages/Turnarounds";
import Turnaround from "../../../pages/Turnaround";
import Export from "../../../pages/Export";
import System from "../../../pages/System";
import Profile from "../../../pages/Profile";
import Alerts from "../../../pages/Alerts";
import WrapperFormGate from "../../../components/FormAddGate/WrapperFormGate";
import {MAIN_PATHS, STANDARD} from "../Paths";
import {All, WithoutValidator, WithoutViewerValidator} from "../Roles";

const {
    turnarounds, turnaround, exports,
    liveView, live, system,
    alerts, profile, formGate
} = MAIN_PATHS

export const primaryList =  [
    {
        path: STANDARD,
        element: <LiveView/>,
        roles: WithoutValidator,
    },
    {
        path: liveView,
        element: <LiveView/>,
        roles: All,
    },
    {
        path: live,
        element: <Live/>,
        roles: All
    },
    {
        path: turnarounds,
        element: <Turnarounds/>,
        roles: All
    },
    {
        path: turnaround,
        element: <Turnaround/>,
        roles: All
    },
    {
        path: exports,
        element: <Export/>,
        roles: WithoutValidator
    },
    {
        path: system,
        element: <System/>,
        roles: WithoutViewerValidator
    },

    {
        path: profile,
        element: <Profile/>,
        roles: All
    },
    {
        path: alerts,
        element: <Alerts/>,
        roles: WithoutViewerValidator
    },
    {
        path: formGate,
        element: <WrapperFormGate/>,
        roles: WithoutViewerValidator
    },
]