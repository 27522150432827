import React from 'react';
import {useSelector} from 'react-redux';
import MarkTimeTeg from "../../ANTD/MarkTimeTeg";
import {returnCorrectTimeZoneTime} from "../../../utils/helpers/timeHelper";
import {selectTimeZone} from "../../../store/selectors/Ui/TimeZone";

const DepartureInfo = ({departure_airport, departure_flight_number, incoming_gate, std_utc, atd_utc, etd_utc}) => {
    const timeZone = useSelector(selectTimeZone);

    return (
        <div className={'wrapperTurnInfo'}>
            <h2>Departure</h2>
            <div className={'turnInformation'}>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>airport: </span>
                    <span>{departure_airport}</span>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>flight number: </span>
                    <span>{departure_flight_number}</span>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>incoming gate: </span>
                    <span>{incoming_gate}</span>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>std: </span>
                    <MarkTimeTeg time={returnCorrectTimeZoneTime(std_utc, timeZone)} empty_text={'NA'}/>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>etd: </span>
                    <MarkTimeTeg time={returnCorrectTimeZoneTime(etd_utc, timeZone)} mark={true} empty_text={'NA'}/>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>atd: </span>
                    <MarkTimeTeg time={returnCorrectTimeZoneTime(atd_utc, timeZone)} mark={true} empty_text={'NA'}/>
                </span>
            </div>
        </div>
    );
};

export default DepartureInfo;