import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, Link} from "react-router-dom"
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import Canvas from "../components/Canvas/Canvas";
import BuildTurnaroundInfo from "../components/TurnaroundInformation/BuildTurnaroundInfo";
import BigLoading from "../components/Loadings/BigLoading";
import {initialTurnInfoState} from "../store/slices/Api/turnaroundInfo";
import {returnCorrectTimeZoneTime} from "../utils/helpers/timeHelper";
import {initialStateFormGate} from "../store/slices/Api/newGate";

import {getCameraRequest} from "../store/asyncThunkFunctions/Api/CamerasRequests";
import {getLive} from "../store/asyncThunkFunctions/Api/ImageRequests";
import {selectCctvFrames} from "../store/selectors/Api/frames";
import {clearImgStates} from "../store/slices/Api/Frames";
import {setErrorImage} from "../utils/helpers/setErrorImageHelper";
import {selectTimeZone} from "../store/selectors/Ui/TimeZone";
import {selectInference, selectTurnInfo} from "../store/selectors/Api/turnaround";
import DetectionCheckboxes from "../components/Detections/DetectionCheckboxes";
import {selectEditCamera} from "../store/selectors/Api/formAddGate";
import {selectSettingsUi} from "../store/selectors/Api/system";
import {saveAs} from 'file-saver';

const Live = () => {
    const {AIRPORT_IATA} = useSelector(selectSettingsUi);
    const {cctv_id, gate_id} = useParams();
    const dispatch = useDispatch();

    const turnaround_info = useSelector(selectTurnInfo);
    const inference = useSelector(selectInference);
    const timeZone = useSelector(selectTimeZone);
    const listOfImages = useSelector(selectCctvFrames);
    const {camera} = useSelector(selectEditCamera);

    const [dataLoading, setDataLoading] = useState(false);
    const [checkedBoxes, setCheckBoxes] = useState({});

    const handleCheckBox = (checkBox) => setCheckBoxes({...checkedBoxes, ...checkBox});

    const downloadImage = () => {
        const file_name = `${AIRPORT_IATA}-${cctv_id}.jpg`
        if (listOfImages[cctv_id].imgLink) {
            return saveAs(listOfImages[cctv_id].imgLink, file_name)
        }
    }

    const callRequests = async () => {
        await setDataLoading(true)

        await dispatch(getCameraRequest({name: gate_id, id: cctv_id}))
        await dispatch(getLive({gate_id, cctv_id}))

        await setDataLoading(false)
    }

    useEffect(() => {
        callRequests()

        return () => {
            dispatch(initialTurnInfoState())
            dispatch(initialStateFormGate());

            dispatch(clearImgStates())
        }
    }, []);


    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(getLive({gate_id, cctv_id}))
        }, 10000)
        return () => clearInterval(timer)
    }, [inference]);

    if (dataLoading) return <BigLoading/>

    return (
        <div className={'liveComponent'}>
            <header className={'liveComponent__liveHeader'}>
                <span>Gate {gate_id}</span>
                <span> | Camera {cctv_id}</span>
            </header>
            <section className={'liveComponent__liveContainer'}>
                <div className={'liveImageContainer'}>
                    <Canvas
                        turnaround_id={turnaround_info?.id}
                        image={setErrorImage(+listOfImages[cctv_id]?.imgError, listOfImages[cctv_id]?.imgLink)}
                        cctv_id={cctv_id}
                        gate_id={gate_id}
                        checkedBoxes={checkedBoxes}
                        inference={inference?.inference}
                        selected={inference?.inference?.detected_objects}
                        isCorrectOption={['all']}
                        downloadImage={downloadImage}
                    />
                    <div className={'liveImageDetections'}>
                        <div className={'liveImageDetections__liveTextTime'}>
                            <span>Frame date & time</span>
                            <span>{returnCorrectTimeZoneTime(inference?.inference?.timestamp, timeZone)}</span>
                        </div>
                        <DetectionCheckboxes
                            camera_position={camera?.position}
                            setCheckBoxes={setCheckBoxes}
                            checkedBoxes={checkedBoxes}
                        />

                    </div>
                </div>
                <div className={'liveTurnInformation'}>
                    <BuildTurnaroundInfo turnaround={turnaround_info}/>
                </div>
            </section>
        </div>
    );
}


export default Live