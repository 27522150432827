import React from 'react';
import {Popconfirm, Tooltip, Button} from "antd";
import {handleOnChangeIsUpdateDataStatus, removeGate} from "../../store/slices/Api/sliceGateRequest";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {initializeGateName} from "../../store/slices/Api/newGate";
import {
    deleteGateRequest,
    editGateNameRequest,
    getListOfGateNames
} from "../../store/asyncThunkFunctions/Api/GateRequest";
import {ConfirmTitles, TooltipTitles} from "../../utils/constants/objects/configuration";
import {ADD_CAMERA, PENCIL, TRASH} from "../icons/antd";
import {FORM_GATE_ADD_CAMERAS_LINK} from "../../utils/routeUtils/Links";
import {selectFormGateDisable} from "../../store/selectors/Api/formAddGate";
import {selectGateNames} from "../../store/selectors/Api/gates";
import classNames from "classnames";
import EditableBtnToggle from "../ANTD/EditableBtnToggle";

const SettingRow = ({isEditing, record, save, cancel, editingKey, edit}) => {
    const disable = useSelector(selectFormGateDisable);
    const {gatesName} = useSelector(selectGateNames);
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const {_id} = record;
    const editable = isEditing(_id);

    const btnClasses = classNames("btn_action_container", {
        "editable_btn_container": editable && !disable,
        "setting_btn_container": !editable,
        "editable_disabled_btn_container": editable && disable
    })

    const handleGetGateNames = () => {if (!gatesName.length) dispatch(getListOfGateNames())}

    const handleEditGateName = async (row) => {
        await dispatch(editGateNameRequest({old: _id, new: row['_id']}))
        await dispatch(getListOfGateNames())
        await dispatch(handleOnChangeIsUpdateDataStatus(true))
    }

    const handleConfirm = () => {
        console.log(_id, "_id")
        dispatch(initializeGateName(_id))
        navigation(FORM_GATE_ADD_CAMERAS_LINK(_id, 'step_two'))
    }

    const handleRemoveGate = async () => {
        await dispatch(deleteGateRequest(_id))
        await dispatch(removeGate(_id))
        await dispatch(handleOnChangeIsUpdateDataStatus(true))
    }


    const handleEdit = () => {
        const _id = record['_id']
        handleGetGateNames()
        edit({_id}, _id)
    }

    return (
        <div className={btnClasses}>
            <EditableBtnToggle
                onSave={disable ? null : () => save(handleEditGateName)}
                cancel={cancel}
                isEdited={editable}
            >
                <Tooltip placement="bottomRight" title={TooltipTitles.editGateName}>
                    <Button icon={PENCIL} disabled={editingKey} onClick={handleEdit}/>
                </Tooltip>
                <Popconfirm title={ConfirmTitles.addCamera} onConfirm={handleConfirm}>
                    <Tooltip placement="bottom" title={TooltipTitles.addCamera}>
                        <Button icon={ADD_CAMERA}/>
                    </Tooltip>
                </Popconfirm>
                <Popconfirm title={ConfirmTitles.removeGate} onConfirm={handleRemoveGate}>
                    <Tooltip placement="bottomLeft" title={TooltipTitles.removeGate}>
                        <Button icon={TRASH}/>
                    </Tooltip>
                </Popconfirm>
            </EditableBtnToggle>
        </div>
    )
};

export default SettingRow;