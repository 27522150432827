import {useEffect, useMemo} from 'react';
import {Button, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import useTable from "../hooks/useTable";
import {injectNewAntdSortSettings, paginationSettings} from "../utils/tableUtils/tableSettings";
import {getPageFids, getStatuses, updatedFids} from "../store/asyncThunkFunctions/Api/TurnaroundRequests";
import {returnCorrectTimeZoneTime} from "../utils/helpers/timeHelper";
import {initialTurnInfoState, toggleIsFetching} from "../store/slices/Api/turnaroundInfo";
import usePagination from "../hooks/usePagination";
import {getListOfGateNames} from "../store/asyncThunkFunctions/Api/GateRequest";
import BuildTurnaroundInfo from "../components/TurnaroundInformation/BuildTurnaroundInfo";
import ModalWindow from "../components/Customs/ModalWindow";
import classNames from "classnames";
import useToggle from "../hooks/useToogle";
import {scrollByY} from "../utils/helpers/tableScrollHelpers";
import {FIDS_BUSY_SPACE} from "../utils/Sizes";
import {fidsColumns} from "../utils/tableUtils/columns/fids";
import {createFilterOptionsSelect} from "../utils/helpers/dataHelpers/arrayObjectModifiers";
import {selectTimeZone} from "../store/selectors/Ui/TimeZone";
import {selectTurnaroundSlice} from "../store/selectors/Api/turnaround";
import {selectGateNames} from "../store/selectors/Api/gates";
import {getStorageValue, setCacheTableSettingsGroup} from "../utils/helpers/cacheHelpers";
import {useSearchParams} from "react-router-dom";

const Turnarounds = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();


    const {turnaround, fids, total, isFetching, loading, statuses, isStartRefresh} = useSelector(selectTurnaroundSlice);
    const timeZone = useSelector(selectTimeZone);
    const {gatesName} = useSelector(selectGateNames);
    const {page, limit, tools, setSettings} = usePagination(1, 10);

    const {getColumnSearchProps} = useTable();

    const previewTurnInfoModal = useToggle();

    const filteredFields = (list) => Object.fromEntries(list.filter(([_, value]) => value))

    const wrapperTurnInfo = classNames('wrapperFidsTurnInfo', previewTurnInfoModal.previewCheckClasses);
    const contentTurnInfo = classNames('contentFidsTurnInfo', previewTurnInfoModal.previewCheckClasses);

    const gate_name_options = useMemo(() => createFilterOptionsSelect(gatesName), [gatesName]);
    const status_options = useMemo(() => createFilterOptionsSelect(statuses), [statuses]);


    const setCorrectField = (col) => {
        if (col['sorter']) {
            return {sortOrder: tools['sort']?.field === col['dataIndex'] && tools['sort']?.order}
        }

        if (col['filterSearch']) {
            return  {filteredValue: tools[col['key']] ? !Array.isArray(tools[col['key']]) ? [tools[col['key']]] : tools[col['key']] : null}
        }
    }

    const modifiedFidsColumn = () => {
        const props = {
            getColumnSearchProps, transformFidsDateTwo,
            gate_name_options, status_options, handleOpenModal,
        }
        return fidsColumns(props).map(col => ({...col, ...setCorrectField(col)}))
    }

    const transformFidsDateTwo = (time) => returnCorrectTimeZoneTime(time, timeZone)

    const handleCloseModal = () => previewTurnInfoModal.close()

    const handleOpenModal = () => previewTurnInfoModal.open()

    const refreshFids = ({page, limit, sort, ...rest}) => {
        const sortSearchParams = injectNewAntdSortSettings(sort);
        const filteredSearchParams = filteredFields(Object.entries({page, limit, ...sortSearchParams, ...rest}));

        setSettings(page, limit, {sort, ...rest});
        setSearchParams(filteredSearchParams);
        dispatch(toggleIsFetching(true))
    }

    const onChange = ({ current: page, pageSize: limit }, filters, {order, field}) => {
        const sort = order ? {order, field} : null
        const storageFields = {page, limit, sort, ...filters}
        const storageFilteredList = filteredFields(Object.entries(storageFields));

        setCacheTableSettingsGroup('fids', storageFilteredList);
        refreshFids(storageFields);
    };

    const modifiedSearchParamsFunc = () => {
        const sortBy = searchParams.get("sort_by");
        const direction = searchParams.get("direction");

        const modifiedList = searchParams.entries().reduce((obj, [key, value]) => {
            if (["page", "limit", "turnaround_id"].includes(key)) {
                obj[key] = value;
            } else {
                if (!obj[key]) obj[key] = [];
                obj[key].push(value);
            }
            return obj;
        }, {});

        if (sortBy && direction) {
            modifiedList.sort = {
                order: Number(direction) === 1 ? "ascend" : "descend",
                field: sortBy
            }
        }
        return modifiedList
    };

    useEffect(() => {
        const fidsTableCacheSettings = getStorageValue('tableGroupsSetting', {}, 'local');
        const searchParamsFields = modifiedSearchParamsFunc();
        const searchParamsSize = searchParams.size

        if (fidsTableCacheSettings.fids && searchParamsSize === 0) {
            refreshFids(fidsTableCacheSettings.fids);
        }

        if (searchParamsSize > 0) {
            refreshFids(searchParamsFields)
        }

        if (!fidsTableCacheSettings.fids && !searchParamsSize) {
            dispatch(toggleIsFetching(true))
        }

        dispatch(getListOfGateNames())
        dispatch(getStatuses())

        return () => {
            console.log('lost Turnarounds page')
            dispatch(initialTurnInfoState())
        }
    }, []);

    useEffect( () => {
        if (isFetching) {
            dispatch(getPageFids({urlParamsList: {page, limit, ...tools}, isRefresh: true}))
        }
    }, [isFetching]);

    useEffect(() => {
        let fetch;
        if (isStartRefresh) {
            fetch = setInterval(() => {
                dispatch(updatedFids({urlParamsList: {page, limit, ...tools}}))
            },  120 * 1000)
        }
        return () => clearInterval(fetch)
    }, [isStartRefresh, tools, page, limit])

    return (
        <>
            <div className={'containerNewTable'}>
                <Table dataSource={fids} columns={modifiedFidsColumn()}
                       onChange={onChange} rowKey={({id}) => id}
                       pagination={{...paginationSettings(page, limit, total)}}
                       scroll={{y: scrollByY(FIDS_BUSY_SPACE)}} loading={loading}
                />
            </div>
            <ModalWindow modalClasses={wrapperTurnInfo} handleClose={handleCloseModal}>
                <div className={contentTurnInfo}>
                    <BuildTurnaroundInfo turnaround={turnaround}/>
                    <Button onClick={handleCloseModal}>Close</Button>
                </div>
            </ModalWindow>
        </>
    );
};

export default Turnarounds;