import {useEffect, useState} from 'react';
import {Button, Card, InputNumber} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {editUiSettings} from "../../../store/asyncThunkFunctions/Api/SystemRequests";
import {selectSettings} from "../../../store/selectors/Api/system";

const PresentationSettings = () => {
    const dispatch = useDispatch();
    const {settingsUI: {TIMELINE_STEPS}, isEditSettingsLoading} = useSelector(selectSettings);
    const [second, setSecond] = useState(0);

    const handleSendSecond = () => dispatch(editUiSettings({TIMELINE_STEPS: Number(second)}))
    const handleOnChangeSecond = (value) => setSecond(value)

    useEffect(() => {
        setSecond(TIMELINE_STEPS)
    }, [TIMELINE_STEPS])

    return (
        <div className={'systemWrapper__presentationWrapper'}>
            <header><h2>Presentation settings</h2></header>
            <section className={'presentationSection'}>
                <Card title={'Camera settings'} className={'presentationSection__presentationCardBlock'}>
                    <section className={'cameraSettingsSection'}>
                        <span>Skip seconds</span>
                        <InputNumber min={1} max={240} value={second} onChange={handleOnChangeSecond}/>
                    </section>
                    <footer className={'cameraSettingsFooter'}>
                        <Button onClick={handleSendSecond} loading={isEditSettingsLoading}>Save</Button>
                    </footer>
                </Card>
            </section>
        </div>
    );
};

export default PresentationSettings;