import React from 'react';
import {Route, Routes} from "react-router-dom";
import {CONFIGURATION_PATHS, fallbackPath, homeLink} from "../utils/routeUtils/Paths";
import FallBackRoute from "../routes/security/FallBackRoute";
import GatesCameras from "../components/Configuration/pages/GatesCameras";
import ImportExport from "../components/Configuration/pages/ImportExport";
import PresentationSettings from "../components/Configuration/pages/PresentationSettings";
import EventsModes from "../components/System/EventsModes";
import MonitoringObjects from "../components/System/MonitoringObjects";

const Configuration = () => {
    const {gates_cameras, import_export, presentation, events, objects} = CONFIGURATION_PATHS

    return (
        <Routes>
            <Route path={gates_cameras} element={<GatesCameras/>}/>
            <Route path={import_export} element={<ImportExport/>}/>
            <Route path={presentation} element={<PresentationSettings/>}/>
            <Route path={events} element={<EventsModes/>}/>
            <Route path={objects} element={<MonitoringObjects/>}/>
            <Route path={fallbackPath} element={<FallBackRoute homeLink={homeLink}/>}/>
        </Routes>
    );
};

export default Configuration;