import React from 'react';
import {Route, Routes} from "react-router-dom";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import useToggle from "../hooks/useToogle";
import {useNavigate} from "react-router-dom";
import {getUser, getUsers, removeUser} from "../store/asyncThunkFunctions/Api/UserRequests";
import Users from "../components/Administration/Users";
import EditUser from "../components/Administration/EditUser";
import AddUser from "../components/Administration/AddUser";
import {selectUsers, selectUser} from "../store/selectors/Api/user";
import Logs from "../components/Administration/Logs";
import FallBackRoute from "../routes/security/FallBackRoute";
import {ADMINISTRATION_LINKS} from "../utils/routeUtils/Links";
import {fallbackPath, homeLink} from "../utils/routeUtils/Paths"

const Administration = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const {open, close, previewCheckClasses} = useToggle();
    const {user} = useSelector(selectUser);
    const {users, usersLoading} = useSelector(selectUsers)

    const {add, update} = ADMINISTRATION_LINKS

    const previewClasses = classNames('sectionPreviewUser', previewCheckClasses);
    const userClasses = classNames('userCard', previewCheckClasses);

    const handleEditUser = (_id) => navigation(`${update}/${_id}`)
    const handleAddNew = () => navigation(add);

    const handleRemoveUser = async (_id) => {
        await dispatch(removeUser(_id))
        await dispatch(getUsers());
    }

    const onChange = (pagination, filters) => {
        const {is_active} = filters
        const filterStatus = is_active?.length === 1 ? (is_active?.join('') ?? '') : null
        dispatch(getUsers(filterStatus ? {is_active: filterStatus === 'active'} : {}))
    };

    const handlePreviewUser = async (_id) => {
        await dispatch(getUser(_id))
        await open()
    }

    const usersProps = {
        onChange, handlePreviewUser,
        handleEditUser,
        handleRemoveUser,
        handleAddNew,
        users, user,
        usersLoading, close,
        previewClasses, userClasses,
    }

    return (
        <Routes>
            <Route path={'users'} element={<Users {...usersProps}/>}/>
            <Route path={'audit'} element={<Logs/>}/>
            <Route path={'update/:login'} element={<EditUser/>}/>
            <Route path={'add'} element={<AddUser/>}/>
            <Route path={fallbackPath} element={<FallBackRoute homeLink={homeLink}/>}/>
        </Routes>
    );
};

export default Administration;