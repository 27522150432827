import React from 'react';
import {Form, Table} from "antd";

const EditableTable = ({form, table}) => {
    return (
        <Form {...form}>
            <Table {...table} />
        </Form>
    );
};

export default EditableTable;