import {Link} from "react-router-dom";
import {CONFIGURATION_LINKS} from "../Links";
const {gateCameras, importExport, presentation, events, objects} = CONFIGURATION_LINKS

export const configurationChildren = [
    {
        label: <Link to={gateCameras}>Gates and Cameras</Link>,
        key: gateCameras
    },
    {
        label: <Link to={events}>Events</Link>,
        key: events
    },
    {
        label: <Link to={objects}>Objects</Link>,
        key: objects
    },
    {
        label: <Link to={presentation}>Presentation Settings</Link>,
        key: presentation
    },
    {
        label: <Link to={importExport}>Import/Export</Link>,
        key: importExport
    }
]